import AboutUs from "../components/AboutUs";
import React, { useEffect } from 'react';
import MainSection from "../components/MainSection";
import Menu from "../components/Menu";
import InfoBlockTogether from "../components/compositions/InfoBlockTogether"
import BigButton from "../components/BigButton"
import Footer from "../components/Footer"
import SliderTemplate from "../components/SliderTemplate";
import { SliderData } from "../components/SliderData";
import SmallInfoTogether from "../components/compositions/SmallInfoTogether";
import FaqComponent from "../components/FaqComponent";
import ReviewTemplate from "../components/ReviewTemplate";
import Form from "../components/Form";
import { ReviewData } from "../components/ReviewData";
import Calculator from "../components/Calculator";
import Map from "../components/Map";
import Gallery from "../components/Gallery";
import { useQuery } from '@tanstack/react-query'
import AxiosContext from "../AxiosContext";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { useState } from "react";

export default function HomePage(){

  const axios = useContext(AxiosContext)

  const fetchHome = async () => {
    const [ faq ] = await Promise.all([
      axios.get("/api/faq/66ade08a54492de91306b9f7?locale=undefined&draft=false&depth=1")
    ]);
    return {
      faq: faq.data
    };
  };

  const { isLoading, data, error } = useQuery({
    queryKey: ['payload'],
    queryFn: fetchHome
  });

  const location = useLocation();

  const [contentLoaded, setContentLoaded] = useState(false);

  useEffect(() => {
    // Simulate content loading with a timeout
    const timer = setTimeout(() => {
      setContentLoaded(true);
    }, 500); // Adjust this delay based on your actual loading time

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const targetId = params.get('targetId');
    console.log(targetId && !isLoading)

    if (targetId && contentLoaded && !isLoading) {
      const targetDiv = document.getElementById(targetId);

      if (targetDiv) {
        console.log(targetDiv.offsetTop)
        const targetScrollPosition = targetDiv.offsetTop -130;
        console.log(targetScrollPosition)
        window.scrollTo({
          top: targetScrollPosition,
          behavior: 'smooth',
        });
      }
    }
  }, [contentLoaded, isLoading, location]);

    return (
        <div id="homePage">
          {!isLoading? 
          <section>
            <Menu/>
            <section style={{margin:"0 auto", height:"90vh", maxWidth:"2000px"}}>
              <MainSection/>   
            </section>
            
            <section id="o-nas" style={{maxWidth:"1140px",marginTop:"100px", margin:"auto"}}>
                
            <InfoBlockTogether/>
            <AboutUs/>
            <Map/>
            <section id="oferta"></section> 
            <SmallInfoTogether/>
            
            <p className="slider-title" style={{maxWidth:"650px", marginLeft: '15px', fontSize:'20px', marginRight:'15px', marginTop:'100px', color:"black"}}>Używane przez nas najwyższej jakości komponenty do produkcji pian 
                poliuretanowych posiadają wiele zalet do których m.in, należą:</p>
            <div>
            
            <SliderTemplate slides={SliderData}/> 
            </div>
            <Gallery/>
            <div id="kalkulator"></div>
            <p id="kalkulator" style={{maxWidth:"800px", fontSize:'20px', marginRight:'15px', marginTop:'120px',marginLeft: '15px', color:"black"}}>
            <span style={{fontSize:"25px"}}>Chciałbyś dowiedzieć się ile moze kosztować twój projekt?</span><br></br>  Kalkulator wycen to autorskie narzędzie które na podstawie kilku informacji jest w stanie oszacować cenę naszych usług.</p>
            <Calculator/>
            <p id="faq" style={{maxWidth:"650px", fontSize:'20px', marginRight:'15px', marginTop:'120px',marginBottom:"50px",marginLeft: '15px', color:"black"}}>
            Interesuje Cię jakaś kwestia? Chcesz poznać odpowiedź na nurtujące Cię pytania? Skorzystaj z naszej sekcji pytań i odpowiedzi!</p>
            <FaqComponent faq={data.faq}/>
            <p className="review-title" style={{maxWidth:"650px", fontSize:'20px', marginRight:'15px', marginLeft: '15px',marginTop:'135px',color:"black"}}> 
            Wiemy, że jedyny słuszny wybór to świadomy wybór! Dlatego zachęcamy Cię do zapoznania się z opiniami naszych klientów:</p>
            <div style={{marginBottom:"100px"}}>
            <ReviewTemplate slides={ReviewData}/> 
            </div>
            <section id="kontakt"></section>
            <p className="review-title" style={{maxWidth:"900px", fontSize:'21px', marginRight:'15px', marginLeft: '15px',marginTop:'135px',color:"black"}}> 
            Chcesz umowić się na wycenę? Masz do nas jakieś pytania? Wyśli do nas wiadomość lub poprostu zostaw swóje informacje kontaktowe. Skontaktujemy się z tobą w przeciągu 24 godzin.</p>
            </section>
            
            <Form/>
            <Footer/>
          </section>:
          <section></section>} 
            
        </div>
    );
}