import React, { Fragment } from 'react';
import escapeHTML from 'escape-html';

const serializeText = (node, i) => {
  let text = <span dangerouslySetInnerHTML={{ __html: escapeHTML(node.text) }} key={i} />;
  
  if (node.bold) {
    text = <strong key={i}>{text}</strong>;
  }
  if (node.code) {
    text = <code key={i}>{text}</code>;
  }
  if (node.italic) {
    text = <em key={i}>{text}</em>;
  }
  if (node.underline) {
    text = <span style={{ textDecoration: 'underline' }} key={i}>{text}</span>;
  }
  if (node.strikethrough) {
    text = <span style={{ textDecoration: 'line-through' }} key={i}>{text}</span>;
  }

  return <Fragment key={i}>{text}</Fragment>;
};

const getHeadingTagStyle = (tag) => {
  switch (tag) {
    case 'h1': return { fontSize: '40px' };
    case 'h2': return { fontSize: '30px' };
    case 'h3': return { fontSize: '24px' };
    case 'h4': return { fontSize: '20px' };
    case 'h5': return { fontSize: '16px' };
    case 'h6': return { fontSize: '14px' };
    default: return { fontSize: '20px' };
  }
};

const serializeNode = (node, i) => {
  if (!node) {
    return null;
  }

  // Handle text nodes
  if (node.type === 'text') {
    return serializeText(node, i);
  }

  // Handle different types of nodes
  switch (node.type) {
    case 'heading':
      const HeadingTag = node.tag;
      const headingStyle = getHeadingTagStyle(node.tag);
      return (
        <HeadingTag key={i} style={{ ...headingStyle, marginTop:"20px", marginBottom:"20px", textAlign: node.format === 'center' ? 'center' : 'left' }}>
          {node.children.map((child, j) => serializeNode(child, j))}
        </HeadingTag>
      );
    case 'quote':
      return (
        <blockquote key={i} style={{ textAlign: node.format === 'center' ? 'center' : 'left' }}>
          {node.children.map((child, j) => serializeNode(child, j))}
        </blockquote>
      );
    case 'list':
      const ListTag = node.listType === 'bullet' ? 'ul' : 'ol';
      return (
        <ListTag style={{marginTop:"20px", marginBottom:"20px"}} key={i}>
          {node.children.map((child, j) => serializeNode(child, j))}
        </ListTag>
      );
    case 'listitem':
      return (
        <li key={i} style={{ fontSize: '20px' }}>
          {node.children.map((child, j) => serializeNode(child, j))}
        </li>
      );
    case 'link':
      return (
        <a href={escapeHTML(node.url)} key={i}>
          {node.children.map((child, j) => serializeNode(child, j))}
        </a>
      );
    case 'hr':
      return <hr key={i} />;
    default:
      return (
        <p key={i} style={{ fontSize: '20px', textAlign: node.format === 'center' ? 'center' : 'left' }}>
          {node.children.map((child, j) => serializeNode(child, j))}
        </p>
      );
  }
};

const serialize = (nodes) => {
  console.log('Nodes:', nodes);
  return nodes.map((node, i) => serializeNode(node, i));
};

export default serialize;
