import { useMemo } from "react";
import "./css/Footer.css";
import iconFb from "../assets/fb.png"
import iconOlx from "../assets/OLX-Logo-PNG_h0bmj9.png"
import call from "../assets/call.png"
import recolte from "../assets/recolte_logo-footer.png"
import oferteo from "../assets/logo_oferteo.png"


const Footer = () => {
  const footerStyle = useMemo(() => {
    return {
      position: "relative"
    };
  }, ["relative"]);

  return (
    <div className="footer" style={footerStyle}>
      <div className="footer-child" />

        <a href="https://www.oferteo.pl/recolte-izolacje/firma/5044689"><div className="yt">
          <img style={{width:"129px", height:"30px"}} className="olx-icon" alt="" src={oferteo} />
          <div className="yt-child" />
        </div></a>
        
      <a href="https://www.facebook.com/TechnologiaIzolacjiNatryskowych/"><div className="fb">
        <img className="fb-icon" alt="" style={{width:"30px", height:"30px"}} src={iconFb} />
        <div className="obserwuj-nas-na">Facebook</div>
        <div className="yt-child" /></div></a>

      <a href="https://recoltepur.olx.pl/home/"><div className="olx">
        <img className="olx-icon" alt="" style={{width:"52px", height:"30px"}} src={iconOlx} />
        <div className="yt-child" /></div></a>
      <div className="phones">
        <h2 className="kontakt">Kontakt</h2>
        <div className="parent">
          <div className="div">793 003 901</div>
          <img className="call-icon" alt="" src={call} />
        </div>
        <div className="group">
          <div className="div1">500 825 787</div>
          <img className="call-icon1" alt="" src={call} />
        </div>
        <div className="group2">
          <div className="div2">kontakt@recolte.pl</div>
          <img className="call-icon1" alt="" src={call} />
        </div>
     
      </div>
      <div className="recolte-logo-container">
        <img className="recolte-logo-icon" style={{width:"129px", height:"82px"}} alt="" src={recolte} />
      </div>
      <div className="footer-item" />
      <a href="https://webstry.pl/"><p className="createdWebstry">Created by Webstry</p></a>
    </div>
  );
};

export default Footer;
