

export const ReviewData = [
    {
      name:"Grzegorz B. ",
      text:'Fachowe doradztwo, szybki termin realizacji. Kompetentni, rzetelni i znający się na rzeczy pracownicy wykonujący ocieplenie, bardzo dobry kontakt z klientem. Polecam.',
      points:5,
      date: "30. sierpnia 2023",
      id:"dot1"
    },
    {
      name:"Lidia J.",
      points:5,
      date:"27 sierpnia 2023",
      id:"dot2",
        text:
        'Świetna i profesjonalna robota, ocieplony został strop. Piana otwarto komórkowa, grubość 25 cm, praca wykonana czysto, schludnie i w umówionym terminie - wzorowy kontakt. Solidnie i uczciwie, polecam każdemu!'
    },
    {
      name:"Robert S.",
      date:"9 sierpnia 2023",
      points:5,
      id:"dot3",
      text:
        'Polecam. Usługa wykonana. Wszystko się zgadza. Kasa, termin, wykonanie.'
    },
    {
      name:"Krzysztof F.",
      date:"21 czerwca 2023",
      points:5,
      id:"dot4",
      text:
        'Fachowe i terminowe wykonanie zlecenia. Oby więcej takich profesjonalistów na rynku.'
    },
    {
      name:"Piotr Z.",
      date:"czerwca 2023",
      points:5,
      id:"dot5",
      text:
        'Wszystko jak najbardziej w porządku. W piwnicy zabezpieczyli nawet słoiki przed zabrudzeniem jak najbardziej polecam.'
    },
    {
      name:"Andrzej G.",
      date:"3 kwietnia 2023",
      points:5,
      id:"dot6",
      text:
        'Profesjonalne podejście do klienta, własny agregat, szybka i sprawna praca. Zdecydowanie polecam!'
    },
    {
      name:"Krzysztof N.",
      date:"kwietnia 2023",
      points:5,
      id:"dot7",
      text:
        'Polecam Szybko znalazłem fachowca w tej dziedzinie Duży odzew'
    },
    {
      name:"Tomasz U.",
      date:"19 lutego 2023",
      points:5,
      id:"dot8",
      text:
        'Profesjonalne podejście do wykonywanej usługi. Dobrze wykonana praca. A tak poza tym fajni ludzie.'
    },
    {
      name:"Jagoda L.",
      date:"17 lutego 2023",
      points:5,
      id:"dot9",
      text:
        'Rzeczowo, szybko, dokładnie, polecam, pełen profesjonalizm.'
    }
  ];