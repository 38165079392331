import React, { useState } from 'react';
import arrowLeft from '../assets/left_arrow.png';
import arrowRight from '../assets/right_arrow.png';
import { SliderData } from './SliderData.jsx';
import './css/Slider.css';

const SliderTemplate = ({ slides }) => {
    const [current, setCurrent] = useState(0);
    const length = slides.length;
  
    const nextSlide = () => {
      setCurrent(current === length - 1 ? 0 : current + 1);
    };
  
    const prevSlide = () => {
      setCurrent(current === 0 ? length - 1 : current - 1);
    };
  
    if (!Array.isArray(slides) || slides.length <= 0) {
      return null;
    }
  
    return (
      <section className='slider'>
        <div onClick={prevSlide} className='left-arrow'></div>
        <div onClick={nextSlide} className='right-arrow'></div>

        {SliderData.map((slide, index) => {
          return (
            <div
              className={index === current ? 'slide active' : 'slide'}
              key={index}
            >
              {index === current && (
                <div className='slider-text-container'>
                    <h3 className='slider-text'>{slide.text}</h3>
                    <div className='dots' id={slide.id} ></div>
                </div>
              )}
            </div>
          );
        })}
      </section>
    );
  };

export default SliderTemplate;