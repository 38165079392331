import picture from "../assets/image1.png";
import picture2 from "../assets/image2.png";

export default function AboutUs () {
    return(
        <section style={{marginTop:"100px"}}>
            <div className="text-home">
                <p className="inner-text">Specjalizujemy się w ociepleniach pianą poliuretanową oraz hydroizolacjach natryskowych. Aby zapewnić najwyższą jakość naszych usług, oferujemy bezpłatnie: wizytę u klienta, doradztwo w zakresie doboru materiałów i technologii, a także wykonanie pomiarów i wyceny planowanej inwestycji.</p>
                <div className="image1">
                    <img className="image" src={picture}></img>
                </div>
            </div>
            <section id="text-home2" className="text-home">
                <div className="image2" >
                    <img className="image" src={picture2}></img>
                </div>
                <div className="inner-text2">
                    <p style={{float:"right"}}>W odpowiedzi na rosnące oczekiwania dotyczące minimalizacji energochłonności budynków, oferujemy ocieplenia przy użyciu systemowych izolacji natryskowych. To metoda niezwykle popularna w Ameryce Północnej, która zdobywa coraz większe uznanie w Europie, ponieważ gwarantuje wyjątkową szczelność izolacji, trudną do osiągnięcia innymi sposobami.</p>
                </div>
               
            </section>
        </section>
    );
}