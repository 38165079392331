export default function MainArticle ({ articles }) {

    const date = new Date(articles.posts[0].date)
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = date.getUTCFullYear();

    return(
        <div className="main-article-container" style={{display:"flex", marginTop:"100px"}}>
            <section className="half1">
                <img className="main-article-img" style={{width:"560px", height:"355px", position:"relative", objectFit:'cover'}} src={`https://admin.recolte.pl${articles.posts[0].upload.url}`}></img>
            </section>
            <section className="half2">
                <h1 className="main-article-h" style={{color:"#1D1D1D", width:"fit-content"}}>{articles.posts[0].title}</h1>
                <p className="main-article-p" style={{wordWrap: 'break-word'}}>{articles.posts[0].description}</p>
                <p style={{fontSize:"15px", marginTop:"25px", color:"grey"}} className="main-article-p">{`Czas czytania: ${articles.posts[0].readingTime} minut • ${day}.${month}.${year}`}</p>
            </section>
        </div>
    );
}