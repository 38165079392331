export const SliderData = [
  {
    id: "dot1",
    text:
      'Precyzyjne pokrycie powierzchni, które eliminuje mostki cieplne.'
  },
  {
    id: "dot2",
    text:
      'Brak przepływu powietrza w izolacji, dzięki czemu ciepło pozostaje w budynku.'
  },
  {
    id: "dot3",
    text:
      'Stała i trwała izolacja bez ryzyka osunięcia się materiału, co eliminuje potrzebę późniejszych napraw.'
  },
  {
    id: "dot4",
    text:
      'Wzmocnienie konstrukcji przez dodatkowe usztywnienie izolowanych elementów.'
  },
  {
    id: "dot5",
    text:
      'Możliwość ocieplenia do 250 m² poddasza w jeden dzień, co przyspiesza realizację projektu.'
  },
  {
    id: "dot6",
    text:
      'Tworzenie szczelnej i jednolitej warstwy hydroizolacyjnej, która skutecznie chroni przed wilgocią.'
  },
  {
    id: "dot7",
    text:
      'Bezpyłowa aplikacja i brak zapachów, co zapewnia komfort podczas prac.'
  },
  {
    id: "dot8",
    text:
      'Łatwa naprawa uszkodzeń (np. po szkodnikach) bez potrzeby demontażu płyt g-k.'
  },
  {
    id: "dot9",
    text:
      'Optymalna izolacja przy minimalnej grubości warstwy, zachowująca doskonałe właściwości termiczne.'
  },
];

