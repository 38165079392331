import { useState, useEffect } from "react";
import animateValue from "./animations";

export default function Calculator () {

    // STATE VARIABLE
    const [data, setData] = useState({
        meters: 120, 
        thickness: 15,
        component: 2.2
    });
    const [errors, setErrors] = useState({})
    const [price, setPrice] = useState(0)
    const [priceUp, setPriceUp] = useState(0)
    const [priceTotal, setPriceTotal] = useState(0)
    const [priceUpTotal, setPriceUpTotal] = useState(0)
    var thickenss_var = {
      15 : 4500,
      20 : 4850,
      25 : 5500,
      30 : 6500 
    };


    const handleMetersChange = (e) => {
        const selectedMeters = e.target.value;
        setData({ ...data, meters: selectedMeters });
        
      };
    
    const handleThicknessChange = (e) => {
        const selectedThinckness = e.target.value;
        setData({ ...data, thickness: selectedThinckness });
        console.log(data.thickness)
      };
  
    //const handleComponentChange = (e) => {
    //    const selectedComponent = e.target.value;
    //    setData({ ...data, component: selectedComponent });
    //    console.log(data.component);
    //  };

      const validateData = () => {
        let errors = {};
        if (data.meters < 120){
          errors.meters = "Zbyt mała ilość metrów aby obliczyć średnią cenę"
        }
        if (data.meters > 250){
          errors.meters = "Zbyt duża ilość metrów aby obliczyć średnią cenę"
        }
        console.log(errors);
        return errors;
      }

      const calculatePrice = () => {

        const errors = validateData();
        if (Object.keys(errors).length){
            setErrors(errors);
            return;
        }
        setErrors({});
        
        var base = data.thickness*1.7*data.meters
        var base_up = data.thickness*2.2*data.meters
        if (data.meters > 160){
          var condition = thickenss_var[data.thickness]+thickenss_var[data.thickness]/160*(data.meters-160)*0.998**(data.meters-160)
        } else {
          var condition = thickenss_var[data.thickness]-thickenss_var[data.thickness]*(160-data.meters)/160*0.98**(160-data.meters)
        }
      
        var price = (base + condition)*1.08/data.meters;
        var price_up = (base_up + condition)*1.08/data.meters;
        var priceTotal = (base + condition)*1.08;
        var priceUpTotal = (base_up + condition)*1.08
       // console.log(data.component)
       // console.log(data.meters)
       // console.log(thickenss_var[data.thickness])
       // console.log(data.thickness)
       // console.log(base)
        price = parseFloat(price).toFixed(2);
        price_up = parseFloat(price_up).toFixed(2);
        priceTotal = parseFloat(priceTotal).toFixed(2);
        priceUpTotal = parseFloat(priceUpTotal).toFixed(2);
       // console.log("Cena: ", price)
       // console.log("Cena up: ", price_up)
        setPrice(price)
        setPriceUp(price_up)
        setPriceTotal(priceTotal)
        setPriceUpTotal(priceUpTotal)
     
        const cenaContainer = document.getElementById("cena-container");
        cenaContainer.style.transition = "ease and out 0.3s"
        cenaContainer.style.display = "flex"
       
      }

    return (
        <div style={{backgroundColor:"#1D1D1D", marginTop:"80px"}}>
            
            <section style={{display:"flex", flexDirection:"column", maxWidth:"600px", margin:"auto"}} className="calculator-container">
                <h1 style={{fontSize:"30px", color:"white", wdith:"fit-content", margin:"auto", marginTop:"20px"}}>Kalkulator wyceny</h1>
                <label htmlFor="meters" style={{color:"white", width:"fit-content",marginTop:"20px", marginBottom:"10px", fontSize:"25px"}}>Metraż ( <span className="msquared">&#13217;</span> )</label>
                <p style={{color: "white"}}>Kalkulator pozwala na wyliczenie ceny od 120 ( <span className="msquared">&#13217;</span> ) do 250 ( <span className="msquared">&#13217;</span> )</p>
                <div className="errorInformation" style={{marginBottom:"10px", marginLeft:"0px"}}>{errors.meters}</div>
                <input
                    id="compression"
                    className="meter-slider"
                    type="range"
                    onChange={handleMetersChange}
                    value={data.meters}
                    min="120" 
                    max="250" 
                    style={{marginBottom:"30px", width:"200"}}
                ></input>
                <input 
                    className="inputNumber" 
                    type="number" 
                    min="120" 
                    max="250" 
                    value={data.meters}
                    onChange={handleMetersChange}
                    style={{height:"50px", fontSize:"20px", width:"150px", backgroundColor:"#737373", border:"none", color:"white", padding:"10px", marginBottom:"40px"}}
                    ></input>
                    <div className="calculator-container-inner">
                        <div style={{display:"flex", flexDirection:"column"}}>
                             <label style={{color:"white", width:"fit-content", marginBottom:"10px", fontSize:"25px"}}>Grubość </label>
                            <select name="grubosc" id="grubosc" onChange={handleThicknessChange}
                            style={{height:"50px", fontSize:"20px", width:"150px", backgroundColor:"#737373", border:"none", color:"white", padding:"10px", marginBottom:"40px"}}
                            >
                                <option value={15}>15cm</option>
                                <option value={20}>20cm</option>
                                <option value={25}>25cm</option>
                                <option value={30}>30cm</option>
                            </select>
                        </div>
                        <div></div>
                      {/* <div style={{display:"none", flexDirection:"column"}}>
                         <label style={{color:"white", width:"fit-content", marginBottom:"10px", fontSize:"20px"}}>Komponenty: </label>
                         <select
                         name="komponent" id="komponent" onChange={handleComponentChange} className="component-select"
                         style={{height:"50px", fontSize:"20px", backgroundColor:"#737373", border:"none", color:"white", padding:"10px", marginBottom:"40px"}}
                         >
                            <option value={2.2}>Silverfoam Ag40</option>
                            <option value={1.8}>UltraPol RG10/30</option>
                            <option value={1.7}>Ultrapol RG 03/10 F-klasa</option>
                    
                         </select>
                       </div>*/}
                       
                    </div>
                    <button className="sendButton" onClick={calculatePrice} style={{marginBottom:"20px", marginTop:"20px", width:"100%"}}>Pokaż cenę</button>
                    <div id="cena-container" style={{paddingBottom:"0px", paddingTop:"20px"}} className="cena-show">
                      <p style={{fontSize:"22px", marginRight:"20px", marginBottom:"50px", color:"white"}}>Cena za ocieplenie będzie mieściła się w przedziale <br></br> 
                      {price} zł - {priceUp} zł za metr. <br></br><br></br>
                      Finalnie projekt będzie kosztował <br></br> 
                      od {priceTotal} zł do {priceUpTotal} zł brutto.</p>
                    </div>
                   
                    <div style={{marginBottom:"50px"}}>
                      <p style={{color: "white"}}>* Koszt będzie zróżnicowany w zależności od wybranego producenta komponentów. Aby uzyskać kompleksową wycenę oraz profesjonalne wsparcie w doborze odpowiednich komponentów, prosimy o kontakt z naszym zespołem. </p>
                    </div>
                </section>
        </div>
    );
}