import Mapa from './../assets/map.png';


export default function Map () {
    return (
        <div style={{marginTop:"100px"}}>
            <p style={{maxWidth:"650px", fontSize:'20px', marginRight:'15px', marginTop:'120px',marginBottom:"50px",marginLeft: '15px', color:"black"}}>
            Świadczymy nasze usługi na obszarze Północno-Zachodniej Polski. Nawet jeśli znajdujesz się poza wyróżnionym obszarem, istnieje szansa, że będziemy w stanie świadczyć nasze usługi również dla Ciebie.
            </p>
            <section>
                <img style={{width:"70%", position:"relative", left:"50%", transform:"translate(-50%)"}} src={Mapa}></img>
            </section>
            
        </div>
    );
}