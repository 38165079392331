import InfoBlock from "../InfoBlock";

export default function InfoBlockTogether () {

    return (
        <div 
        className="info-block-together"
         style={{
            gridTemplateColumns:"[First] 32% [Margin] 1% [Second] 34% [Margin2] 1% [Third] 32%",
            width:"100%", 
            position:"relative", 
            left:"50%", 
            transform:"translate(-50%)"}}>
                <InfoBlock
                number="84 000+"
                content="ocieplone metry kwadratowe"
                />
                <div></div>
                <InfoBlock
                number="57+"
                content="zadowolonych klientów"/>
                <div></div>
                <InfoBlock
                number="50 000+"
                content="przejechanych kilometrów"/>
        </div>
    );
}