import Footer from "../components/Footer";
import Menu from "../components/Menu";
import { useEffect } from "react";

export default function ThankYouPage() {

    useEffect(() => {
        window.gtag('config', 'AW-16685760834');
        window.gtag('event', 'conversion', { 'send_to': 'AW-16685760834/RpaYCIL5os4ZEMKCspQ-' });
    }, []);

    return (
        <div>
            <Menu/>
            <div style={{height:"130px"}}></div>
            <h1 style={{color:"black", width:"fit-content", margin:"auto", marginTop:"200px"}}>Zapytanie zostało pomyślnie wysłane!</h1>
            <h2 style={{color:"black", width:"fit-content", margin:"auto", marginTop:"50px"}}>Skontaktujemy się z toba w przeciągu 24 godzin.</h2>
            <div style={{width:"fit-content", margin:"auto"}}>
                <a href="https://recolte.pl/blog"><button className="sendButton" id="thank-you-buttons" style={{margin:"50px"}}>Odwiedź nasz blog</button></a>
                <a href="https://recolte.pl/"><button className="sendButton" id="thank-you-buttons">Powrót do strony głównej</button></a>
            </div>
            <Footer/>
        </div>
    );
    
}