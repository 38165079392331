import React, { Component } from "react";
import star from "../assets/star.png";

class Stars extends Component {
  constructor(props) {
    super(props);
    this.state = {
      points: props.points
    };
  }

  render() {
    return (
      <div>
          {Array(this.state.points).fill(<img style={{marginRight:"10px"}} src={star}/>)}
      </div>
    );
  }
}

export default Stars;