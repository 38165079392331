import WpisComponent from "../components/WpisComponent"
import BlogPage from "./BlogPage"
import HomePage from "./HomePage"
import ThankYouPage from "./ThankYouPage"

const routes = [
	{
		path: "/",
		element: <HomePage/>
	},
	{
		path:"/blog",
		element: <BlogPage/>
	},
	{
		path:"/wpis",
		element: <WpisComponent/>
	},
	{
		path:"/posts/:postID",
		element: <WpisComponent/>
	},
	{
		path:"wycena",
		element: <ThankYouPage/>
	}

]

export default routes
