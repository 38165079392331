import Faq from "./Faq";

export default function FaqComponent (faq) {

    console.log(faq.faq)

    return (
        <div>
            {faq.faq.questions.map((element, index) => (
                <Faq
                key={index}
                title={element.question}
                content={element.answer}
                />
            ))
            }
        </div>
    );
}