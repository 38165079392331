import Footer from "./Footer";
import Menu from "./Menu";
import AxiosContext from "../AxiosContext";
import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from 'react-router-dom';
import serialize from "./serialize";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function WpisComponent () {

    const axios = useContext(AxiosContext)
    const params = useParams()
    const navigate = useNavigate()

    const fetchArticle = async () => {
        const [ article ] = await Promise.all([
            axios.get('/api/post/66ade18a54492de91306ba2c?locale=undefined&draft=false&depth=1')
        ])
        console.log(article.data.posts.filter((element) => element.id == params.postID)[0].layout[0].content)
        return article.data.posts.filter((element) => element.id == params.postID)
    }

    const { isLoading, data, error } = useQuery({
        queryKey: ['article'],
        queryFn: fetchArticle
      });

     let day, month, year
      
      if(!isLoading){
        let date = new Date(data[0].date)
        day = String(date.getUTCDate()).padStart(2, '0');
        month = String(date.getUTCMonth() + 1).padStart(2, '0');
        year = date.getUTCFullYear();
      }

      const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const isMobile = windowWidth <= 900;
    
    return (
        <div>
            <Menu/>
            {!isLoading? 
            <section>
                <div style={{width:"100%",position:"relative", top:"200px"}}>
                    <img className="article-img2" style={{maxWidth:"1140px", width:"100%", height:"450px", objectFit:"cover", position:"relative", left:"50%", transform:"translate(-50%)"}} src={`https://admin.recolte.pl${data[0].upload.url}`}></img>
                    <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    maxWidth:"1140px",
                    left: "50%",
                    transform: "translate(-50%)",
                    height: '450px',
                    background: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.4) 70%,  rgba(0,0,0,0.8) 100%)'
                    }}></div>
                    <h1 style={{maxWidth:"1140px",position:"relative", left:"50%", transform:"translate(-50%)", color:"white", fontSize:"35px"}} className="article-font-h">{data[0].title}</h1>
                    <p style={{maxWidth:"1140px",position:"relative", left:"50%", transform:"translate(-50%)", color:"#DCDDDD"}} className="article-font-mini-p">{`Czas czytania: ${data[0].readingTime} minut • ${day}.${month}.${year}`}</p>
                </div>
                <section style={{maxWidth:"1140px", width:"100%", margin:"auto"}}>
                    <section style={{width:"99%",height:"100%", marginTop:"190px", color:"black", wordBreak:"break-word"}}>
                        {data[0].layout.map((element, index) => (
                            <React.Fragment key={index}>
                            {console.log(element)}
                            {element.blockType === "textImageLeft" ? (
                              <section style={{ display: "flex", flexDirection: isMobile ? 'column' : 'row' }}>
                                <div style={{ width: isMobile ? '90%' : "50%", margin:"20px"}}>
                                  {serialize(element.content.root.children)}
                                </div>
                                <div style={{ width: isMobile ? '90%' : "50%" }}>
                                  <img
                                    style={{ width: "90%", marginTop:"50px", marginLeft:"20px" }}
                                    src={`https://admin.recolte.pl${element.image.url}`}
                                    alt="" // Add alt text for accessibility
                                  />
                                </div>
                              </section>
                            ) : element.blockType === "textImageRight" ? (
                              <section style={{ display: "flex", flexDirection: isMobile ? 'column' : 'row' }}>
                                <div style={{ width: isMobile ? '100%' : "50%" }}>
                                  <img
                                    style={{ width: "90%", marginRight: "50px", marginTop: "50px", marginLeft:"20px" }}
                                    src={`https://admin.recolte.pl${element.image.url}`}
                                    alt="" // Add alt text for accessibility
                                  />
                                </div>
                                <div style={{width: isMobile ? '90%' : "50%", margin:"20px" }}>
                                  {serialize(element.content.root.children)}
                                </div>
                              </section>
                            ) : element.blockType === "title" ? (
                                <div><h1 style={{fontSize:"45px", margin:"auto", textAlign:"center", margin:"50px 50px"}}>{element.title}</h1></div>
                                
                            )
                            : element.blockType === "image" ? (
                                <div>
                                    <img style={{width:"100%" ,position:"relative", left:"50%",marginTop:"30px", marginBottom:"30px" , transform:"translate(-50%)"}} src={`https://admin.recolte.pl${element.image.url}`}/>
                                </div>
                            )
                            : element.blockType === "cta" ? (
                                <div>
                                    <button onClick={()=> navigate(`${element.link}`)} style={{
                                        position:"relative", 
                                        transform: element.allign == 'left' ? "translate(0%)" : element.allign == 'centre' ? "translate(-50%)" : "translate(-100%)", 
                                        left: element.allign == 'left' ? "0%" : element.allign == 'centre' ? "50%" : "100%", 
                                        width:"300px", height:"60px", border:"2px solid black", fontSize:"22px", fontWeight:"bolder"}} className="button-menu">{element.title}</button>
                                </div>
                            )
                            : (
                              <div style={{width: "95%", margin:"20px"}}>
                                {serialize(element.content.root.children)}
                              </div>
                            )}
                          </React.Fragment>
                        ))}
                    </section>
                </section>
                <section style={{maxWidth:"1222px", margin:"auto", marginTop:"100px"}}>
               {/* <h3 className="wpisy" style={{color:"#1D1D1D", fontSize:"30px", marginBottom:"40px"}}>Więcej artykułów</h3> 
                <div className="article-container">
                    {/*<a href="/wpis" style={{cursor:"pointer"}}>
                    <ArticleComponent
                        height="219px"
                        image={image1}
                        fontSizeH2="30px"
                    />  
                    </a>
                    
                 
                    <ArticleComponent
                    height="219px"
                    image={image1}
                    fontSizeH2="30px"
                    />
                 
                    <ArticleComponent
                    height="219px"
                    image={image1}
                    fontSizeH2="30px"
                    /> 
                </div>   */}
            </section>
            </section>:
            <section></section>}
                
            <Footer/>
        </div>
    ); 
}