import React from 'react';
import { useNavigate } from 'react-router-dom';

const ScrollButton = ({ targetId, name, nameClass, idName }) => {
  const navigate = useNavigate();

  const scrollToTarget = () => {
    navigate(`/?targetId=${targetId}`);
  };

  return (
    <button style={{border:"none"}} className={nameClass} id={idName} onClick={scrollToTarget}>
      {name}
    </button>
  );
};

export default ScrollButton;