import ocieplenieNiebo from "../assets/ocieplenie-niebo.png"
import ocieplenieAuto from "../assets/ocieplenie-auto.png"
import ocieplenieDom from "../assets/ocieplenie-dom.png"
import ocieplenieHangar from "../assets/ocieplenie-hangar.png"
import ocieplenieMagazyn from "../assets/ocieplenie-magazyn.png"
import ocieplenieOk from "../assets/ocieplenie-ok.png"
import ociepleniePoddasze from "../assets/ocieplenie-poddasze.png"
import ociepleniePoddasze2 from "../assets/ocieplenie-poddasze2.png"
import ocieplenieZabawa from "../assets/ocieplenie-zabawa.png"
import Auto from "../assets/auto.png"

import autoMobile from "../assets/auto-mobile.png"
import magazynMobile from "../assets/magazyn-mobile.png"
import nieboMobile from "../assets/niebo-mobile.png"
import ocieplenieMobile from "../assets/ocieplenie-mobile.png"
import pracaMobile from "../assets/praca1-mobile.png"
import standrardMobile from "../assets/standrard-mobile.png"
import vanMobile from "../assets/van-mobile.png"
import zabawaMobile from "../assets/zabawa-mobile.png"

export default function Gallery () {
    return(
        <div style={{width:"100%"}}>
            <section className="desktop-gallery">
                <div>
                    <img className="galeria-zdjecia" src={ocieplenieNiebo}/>
                    <img className="galeria-zdjecia" style={{position:"relative", top:"135px"}} src={Auto}/>
                    <img className="galeria-zdjecia" style={{position:"relative", top:"135px"}} src={ociepleniePoddasze}/>
                </div>
                <div>
                    <img className="galeria-zdjecia" src={ocieplenieAuto}/>
                    <img className="galeria-zdjecia" style={{position:"relative", bottom:"45px"}} src={ocieplenieDom}/>
                    <img className="galeria-zdjecia" style={{position:"relative"}} src={ociepleniePoddasze2}/>
                </div>
                <div>
                    <img className="galeria-zdjecia"  style={{position:"relative", bottom:"45px"}} src={ocieplenieHangar}/>
                    <img className="galeria-zdjecia" style={{position:"relative", bottom:"45px"}} src={ocieplenieMagazyn}/>
                    <img className="galeria-zdjecia" style={{position:"relative", bottom:"45px"}} src={ocieplenieOk}/>
                    <img className="galeria-zdjecia" style={{position:"relative", bottom:"45px"}} src={ocieplenieZabawa}/>
                </div>
            </section>
            <section className="mobile-gallery" style={{position:"relative", top:"70px"}}>
                <div style={{width:"fit-content", margin:"auto"}}>
                    <img className="galeria-zdjecia" src={autoMobile}></img>
                    <img className="galeria-zdjecia" src={magazynMobile}></img>
                </div>
                <div style={{width:"fit-content", margin:"auto"}}>
                    <img className="galeria-zdjecia" src={nieboMobile}></img>
                </div>
                <div style={{width:"fit-content", margin:"auto"}}>
                    <img className="galeria-zdjecia" src={vanMobile}></img>
                    <img className="galeria-zdjecia" src={pracaMobile}></img>
                    <img className="galeria-zdjecia" src={zabawaMobile}></img>
                </div>
                <div style={{width:"fit-content", margin:"auto"}}>
                    <img className="galeria-zdjecia" src={standrardMobile}></img>
                    <img className="galeria-zdjecia" src={ocieplenieMobile}></img>
                </div>
            </section>
        </div>
    );
}