import SmallInfo from "../SmallInfo";

export default function SmallInfoTogether () {
    return(
        <div style={{marginTop:"100px", marginLeft:"15px", marginRight:"15px"}}>
            <p style={{marginBottom:"35px", color:"black", fontSize:"20px"}}>Dzieki nam ocieplisz: </p>
            <section className="desktop" style={{flexDirection:"row",width:"fit-content", position:"relative", left: "50%", transform:"translate(-50%)"}}>
                <SmallInfo text="stropy" paddingLeft="27px" paddingRight="27px" margin="20px 20px" fontSize="25px"/>
                <SmallInfo text="dachy" paddingLeft="27px" paddingRight="27px"  margin="20px 20px" fontSize="25px"/>
                <SmallInfo text="hale przemyslowe" paddingLeft="27px" paddingRight="27px"  margin="20px 20px" fontSize="25px"/>
                <SmallInfo text="fundamenty" paddingLeft="27px" paddingRight="27px" margin="20px 20px" fontSize="25px"/>
                <SmallInfo text="dachy plaskie" paddingLeft="27px" paddingRight="27px" margin="20px 20px" fontSize="25px"/> 
            </section>
            <section className="desktop" style={{flexDirection:"row",width:"fit-content", position:"relative", left: "50%", transform:"translate(-50%)"}}>
                <SmallInfo text="domy o konstrukcji szklieletowej" paddingLeft="22px" paddingRight="22px" margin="20px 20px" fontSize="25px"/>
                <SmallInfo text="poddasza" paddingLeft="22px" paddingRight="22px" margin="20px 20px" fontSize="25px" />
                <SmallInfo text="budynki inwestycyjne" paddingLeft="22px" paddingRight="22px" margin="20px 20px" fontSize="25px"/>
                <SmallInfo text="sciany" paddingLeft="22px" paddingRight="22px" margin="20px 20px" fontSize="25px"/>
            </section>


            <section className="mobile" style={{ flexDirection:"row",width:"fit-content", position:"relative", left: "50%", transform:"translate(-50%)"}}>
               <SmallInfo text="stropy"  paddingLeft="33px" paddingRight="33px" margin="20px 20px" fontSize="20px"/>
                <SmallInfo text="dachy" paddingLeft="33px" paddingRight="33px" margin="20px 20px" fontSize="20px"/>
            </section>
            <section className="mobile" style={{ flexDirection:"row",width:"fit-content", position:"relative", left: "50%", transform:"translate(-50%)"}}>
                <SmallInfo text="hale przemyslowe" paddingLeft="72px" paddingRight="72px" margin="20px 20px" fontSize="20px" />
            </section>
            <section className="mobile" style={{ flexDirection:"row",width:"fit-content", position:"relative", left: "50%", transform:"translate(-50%)"}}>
                <SmallInfo text="fundamenty" paddingLeft="97px" paddingRight="97px" margin="20px 0px" fontSize="20px"/>
            
            </section>
            <section className="mobile" style={{ flexDirection:"row",width:"fit-content", position:"relative", left: "50%", transform:"translate(-50%)"}}>
                <SmallInfo text="poddasza" paddingLeft="25px" paddingRight="25px" margin="20px 20px" fontSize="20px"/>
                <SmallInfo text="sciany" paddingLeft="25px" paddingRight="25px" margin="20px 20px" fontSize="20px"/>
            </section>
            <section className="mobile" style={{ flexDirection:"row",width:"fit-content", position:"relative", left: "50%", transform:"translate(-50%)"}}>
                <SmallInfo text="dachy plaskie" paddingLeft="90px" paddingRight="90px" margin="0px 0px" fontSize="20px" /> 
            </section>
            
        </div>
    );
}