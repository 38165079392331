

export default function ArticleComponent(props){

    const date = new Date(props.post.date)
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = date.getUTCFullYear();

    return(
        <div style={{width:"100%", height:"428", color:"#1D1D1D"}}>
            <div style={{width:"100%",backgroundColor:"grey" , height:props.height}}>
                <img style={{width:"100%", height:"100%", objectFit:'cover'}} src={props.image} ></img>
            </div>
            <h2 className="blog-article-h" style={{fontSize:props.fontSizeH2, lineHeight:"110%", marginTop:"20px", marginBottom:"10px"}}>{props.post.title}</h2>
            <p className="blog-article-p" style={{fontSize:"20px", maxWidth:"100%", wordWrap: 'break-word'}}>{props.post.description}</p>
            <p className="blog-article-p2" style={{fontSize:"15px", marginTop:"12px", color:"grey"}}>{`Czas czytania: ${props.post.readingTime} minut • ${day}.${month}.${year}`}</p>
        </div>
    );
}