import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
	createBrowserRouter,
	RouterProvider
} from 'react-router-dom';
import routes from './pages/routes';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import TagManager from 'react-gtm-module'

const queryClient = new QueryClient()
const tagManagerArgs = {
  gtmId: 'GTM-K753BB9C'
}
TagManager.initialize(tagManagerArgs)

const router = createBrowserRouter(routes);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>  
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router}/>
    </QueryClientProvider>
  </React.StrictMode>
);

reportWebVitals();
