import React, { useState } from "react";
import validator from "validator";
import * as Checkbox from '@radix-ui/react-checkbox';
import { CheckIcon } from '@radix-ui/react-icons';

const Form = ({onSave, user={}}) =>  {

    const [userData, setUserData] = useState(user);
    const [errors, setErrors] = useState({});
    const [check, setCheck] = useState(false);
    const {name, email, message, post_code, city} = userData;
    const [loading, setLoading] = useState(false);

     //Checking the correctness of inputs
     const validateData = () => {
        let errors = {};
        if (!name){
            errors.name = "Imie jest wymagane";
            console.log(errors.name);
        }
        if (!email) { 
            errors.email = "Email jest wymagany";
        } else {
            if (!validator.isEmail(email)){
                errors.email = "Poprawny Email jest wymagany";
                }
        }
        if (!post_code){
            errors.kod = "Kod pocztowy jest wymagany "
        }
        if (!city){
            errors.miasto = "Nazwa miasta jest wymagana"
        }
        if (check == false){
            errors.checking = "Musisz potwierdzic plityke prywatności";
        }
        console.log(errors);
        return errors;
    }
    
    const handleToggle = () => {
        setCheck((current) => !current);
        console.log(check);
      };

    //Handling of the changes in inputs
    const handleChange = (event) => {
        const {name, value} = event.target;
        setUserData((prevData) => ({...prevData, [name]: value }));
    };

    const handleSave = async () => {
        const errors = validateData();
        if (Object.keys(errors).length){
            setErrors(errors);
            console.log(check)
            return;
        }
        setErrors({});
        console.log(userData);
        setLoading(true)

        try {
            const req = await fetch('https://admin.recolte.pl/api/form', {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(userData),
            });
            const resData = await req.json();
            console.log(resData)
            window.location.replace('https://recolte.pl/wycena');
          } catch (err) {
            console.log(err);
          }
    }

    return (
        <div style={{backgroundColor:"#1D1D1D"}}>
            <div style={{maxWidth:"1140px", margin:"auto"}}>
            <h1 className="form-title" style={{width:"fit-content", color:"white", fontSize:"50px", paddingTop:"40px", marginBottom:"40px"}}>Skontaktuj się z nami</h1>
            <section className="form-container">
                <section className="form-container-inner">
                    <input className="form-input" style={{width:"100%", height:"56px"}} placeholder="imie i nazwisko" name="name" onChange={(e) => handleChange(e)}></input>
                    <div className="errorInformation">{errors.name}</div>
                    <input className="form-input" style={{width:"100%", height:"56px"}} placeholder="E-mail" name="email" onChange={(e) => handleChange(e)}></input>  
                    <div className="errorInformation">{errors.email}</div>
                    <input className="form-input" style={{width:"100%", height:"56px"}} placeholder="Kod pocztowy" name="post_code" onChange={(e) => handleChange(e)}></input>  
                    <div className="errorInformation">{errors.kod}</div>
                    <input className="form-input" style={{width:"100%", height:"56px"}} placeholder="Miasto" name="city" onChange={(e) => handleChange(e)}></input>  
                    <div className="errorInformation">{errors.miasto}</div>
                </section>  
                    <textarea className="form-input" id="form-input-message" style={{height:"284px"}} placeholder="wiadomosc (opcjonalne)" name="message" onChange={(e) => handleChange(e)}></textarea>
                    <div className="errorInformation">{errors.message}</div>    
            </section>
            <section className="bottom-form">
                <div></div>
                <div className="administrator-info" style={{float: "left", display: "flex", flexDirection: "row"}}>
                            <Checkbox.Root className="CheckboxRoot" onCheckedChange={handleToggle}  id="c1" >
                            <Checkbox.Indicator className="CheckboxIndicator">
                                <CheckIcon/>
                            </Checkbox.Indicator>
                            </Checkbox.Root>
                            <label style={{color:"white", fontSize:"15px"}} className="Label" htmlFor="c1">
                                Zapoznałem się z <u>informacją o administratorze i przetwarzaniu danych</u>
                            </label>                            
                </div>
                <div className="sendButton" style={{border:"none", borderRadius:"0px", textAlign:"center"}} onClick={handleSave}>
                    {!loading ?
                    <p style={{position:"relative", top:"8px"}}>Wyslij</p> :
                    <div class="loadingio-spinner-rolling-3d1gtw28m9y"><div class="ldio-t3jts6k398p"><div></div></div></div>}
                </div>
                
            </section>
            <div className="errorInformation" id="checking-error">{errors.checking}</div> 
            </div>
            
        </div>
    );
}

export default Form;