import React, { useState } from 'react';

export default function Faq(props) {
    const [clicked, setClicked] = useState(false);
    const [height, setHeight] = useState("0px");
    const [opacity, setOpacity] = useState(0);  // State to control opacity

    const handleClick = () => {
        if (!clicked){
            setHeight("200px");
            setOpacity(1);  // Make content visible
        } else {
            setHeight("0px");
            setOpacity(0);  // Hide content
        }
        
        setClicked(!clicked);
    };

    return (
        <div style={{ margin: "auto", marginBottom: "20px", maxWidth: "1140px" }}>
            <div className="faqUpper" onClick={handleClick} style={{ cursor: "pointer", width: "100%", position: "relative", zIndex: "2" }}>
                <p style={{ padding: "10px", marginLeft: "20px", fontSize: "20px", color: "white" }}>{props.title}</p>
                <div className={clicked ? "faq-close" : "faq-open"}></div>
            </div>
            <div 
                id={`faqContent-${props.id}`} // Unique ID using the key or id prop
                onClick={handleClick} 
                style={{ cursor: "pointer", width: "100%", height: height, backgroundColor: "#1D1D1D", transition: "height 0.3s ease" }}
            >
                <div 
                    style={{ 
                        position: "relative", 
                        marginLeft: "20px", 
                        height: "80%", 
                        width: "90%", 
                        top: "50%", 
                        transform: "translate(0,-50%)", 
                        borderTop: "solid 1px white", 
                        opacity: opacity,  // Use opacity state
                        transition: "opacity 0.3s ease"  // Smooth transition
                    }}
                >
                    <p 
                        key={props.key} 
                        className='answer' 
                        style={{ fontSize: "20px", marginTop: "10px", zIndex: "1", position: "relative", color: "white" }}
                    >
                        {props.content}
                    </p>
                </div>
            </div>
        </div>
    );
}