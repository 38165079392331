

export default function InfoBlock (props) {
    return (
        <div 
        className="info-block"
        style={{
            height: "200px", 
            backgroundColor: "#1D1D1D"}}>
                <p id={props.id} style={{color:"white", textAlign:"center", fontWeight:"500", fontSize:"70px"}}>{props.number}</p>
                <p style={{color:"white", textAlign:"center", fontWeight:"300", fontSize:"30px"}}>{props.content}</p>
        </div>
    ); 
}