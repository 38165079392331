import './globals.css';
import Menu from './components/Menu';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'

const queryClient = new QueryClient()

function App() {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
      <Menu/>
      </QueryClientProvider>
    </div>
  );
}

export default App;
